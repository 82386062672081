<script setup>

import {onMounted, ref} from "vue";
import axios from 'axios';
import CompetitorShootOutRow from "./CompetitorShootOutRow.vue";

const show = ref(false);
const results = ref({});
const props = defineProps(['tournament'])
const loading = ref(true);

onMounted(() => {
  axios.get(`/tournaments/${props.tournament.slug}/team_ladder`).then(({data}) => {
    if (data.success) {
      results.value = data.data;
    }
    loading.value = false;
  }).catch(() => {
    loading.value = false;
  })
},)
</script>

<template>
  <div>
    <div class="flex">
      <div class="btn btn-blue-600 cursor-pointer" @click="show = !show">
        {{ show ? 'Hide' : 'Show' }} Team Results
      </div>
    </div>
    <div v-if="show" class="bg-gray-200 border-gray-500 border rounded p-2 mt-2">
      <div v-if="loading" class="flex justify-center">
        <div class="my-10 text-center">
          <div class="mb-5 text-xl">Calculating Team Results</div>
          <i class="fa fa-2x fa-spinner fa-spin"></i>
        </div>
      </div>
      <div v-else>
        <div v-if="Object.keys(results).length === 0">
          No results to show. If this is unexpected, make sure that archers have scored, and that they have selected a
          team in the Team/Club Name registration option.
        </div>
        <div v-else class="text-center text-3xl font-bold">
          Team Results
        </div>
        <div v-for="ladder of Object.keys(results)" class="mb-8">
          <div class="ladderHeader">
            {{ parseInt(ladder) + 1 }}. {{ results[ladder]['name'] }} - {{ results[ladder]['total'] }} points
          </div>
          <div class="table-responsive">
            <table class="table table-striped table-bordered">
              <thead>
              <tr>
                <th class="table-header">Competitor</th>
                <th class="table-header">Ladder</th>
                <th class="table-header">Points</th>
              </tr>
              </thead>
              <tbody>
              <template v-for="competitor of Object.keys(results[ladder])">
                <tr v-if="competitor !== 'total' && competitor !== 'name'">
                  <td>{{ results[ladder][competitor].competitor }}</td>
                  <td>{{ results[ladder][competitor].ladder }}</td>
                  <td>{{ results[ladder][competitor].points }}</td>
                </tr>
              </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.ladderHeader {
  @apply text-2xl font-bold;
}
</style>