<template>
  <div id="printResults">
    <TeamLadderResults :tournament="tournamentIn"/>
    <div v-for="ladder in tournamentIn.ladders" class=" px-0 pb-5" :style="`overflow:scroll;`">
      <h1 class="text-3xl pb-2">{{ ladder.name }}</h1>
      <div v-if="ladder.shoot_out">
        <div class="table-responsive">
          <table class="table table-striped table-bordered">
            <thead>
            <tr>
              <th class="table-header">Competitor</th>
              <th v-for="count in ladder.start_ladder - 1" class="table-header"> Step {{ count }}</th>
            </tr>
            </thead>
            <tbody>
            <competitor-shoot-out-row
              v-for="competitor in ladder.start_ladder"
              :key="`shootOut_${competitor}_row`"
              :tournament-in="tournamentIn"
              :ladder="ladder"
              :ordered-competitors="orderedCompetitors(ladder)"
              :competitor-index="competitor"/>
            </tbody>
          </table>
        </div>
      </div>
      <div v-else>
        <div class="absolute w-full" style="padding-right:2px;">
          <div style="height:45px;"></div>
          <div
            v-for="count in ladder.end_ladder"
            :style="`height: ${height(columns(ladder) - 1, ladder)}px;`"
            :class="count % 2 === 0 ? 'bg-blue-100' : 'bg-blue-200'"/>
        </div>
        <div class="flex relative px-2" style="z-index:10;" :style="`width:${ladderCounts(ladder).length * 250}px`">
          <div v-for="(starting, step) in ladderCounts(ladder)" class="flex-1">
            <div style="height:45px;" class="text-center">Step {{ step + 1 }}</div>
            <div
              v-for="(number, numberIndex) in starting"
              class="mr-4 flex flex-col justify-center"
              :style="`height:${height(step, ladder)}px;`">
              <competitor-ladder-results
                :tournament-in="tournamentIn"
                :ladder="ladder.id"
                :step="step"
                :bale="baleFromNumber(number)"
                :position="positionFromNumber(number)"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CompetitorLadderResults from "./CompetitorLadderResults.vue";
import CompetitorShootOutRow from "./CompetitorShootOutRow.vue";
import TeamLadderResults from "./TeamLadderResults.vue";

export default {
  name: "LadderResults",
  components: {TeamLadderResults, CompetitorShootOutRow, CompetitorLadderResults},
  props: ['tournamentIn'],
  data() {
    return {
      rowHeight: 45.0,
    }
  },
  methods: {
    orderedCompetitors(ladder) {
      let list = [];
      let vm = this;
      for (let i = 0; i < ladder.start_ladder; i++) {
        if (i === 0) {
          let assignment = vm.tournamentIn.ladder_assignments.find(a => {
            return a.ladder_id === ladder.id && a.step === i && a.bale === 2;
          })
          list.push(assignment != null ? assignment.competitor_id : null);
        }
        let assignment = vm.tournamentIn.ladder_assignments.find(a => {
          return a.ladder_id === ladder.id && a.step === i && a.bale === 1;
        })
        list.push(assignment != null ? assignment.competitor_id : null);
      }
      return list.reverse();
    },
    height(column, ladder) {
      return this.heightMatrix(ladder)[column];
    },
    columns(ladder) {
      return this.ladderCounts(ladder).length;
    },
    ladderCounts(ladder) {
      let list = [];
      let start = ladder.start_ladder;
      let end = ladder.end_ladder;
      while (start >= end) {
        list.push(start);
        start = start / 2;
      }
      return list;
    },
    heightMatrix(ladder) {
      let list = [];
      let number = this.rowHeight;
      for (let column = 0; column < this.columns(ladder); column++) {
        list.push(number);
        number = number * 2;
      }
      return list;
    },
    baleFromNumber(number) {
      let halfNumber = number / 2;
      let isA = halfNumber % 1 !== 0.00;
      let bale = isA ? Math.ceil(halfNumber) : halfNumber;
      return bale;
    },
    positionFromNumber(number) {
      let halfNumber = number / 2;
      let isA = halfNumber % 1 !== 0.00;
      let position = isA ? 'A' : 'B';
      return position;
    }
  }
}
</script>
<style scoped>

</style>