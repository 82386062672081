<template>
  <div class="p-1 mb-3 w-full md:w-2/3 lg:1/2 xl:w-1/4">
    <div class="flex flex-col">
      <div class="flex-grow bs-form-box nameHolder">
        <div class="btn rounded-r-none w-10 flex-none" :class="competitor != null ? 'btn-green-500' : 'btn-red-500'">
          <span>{{ number }}</span>
        </div>
        <input
          class="searchBox h-center boxContent"
          :ref="`input_${number}`"
          v-model="search" tabindex="1"
          :placeholder="assignment != null ? competitor.name : 'type to filter'"
          v-if="editing">
        <div v-else class="h-center boxContent" :class="competitor != null ? '' : 'text-gray-500'">
          <span class="nameBox">
            {{ competitor != null ? name : 'Open' }}
          </span>
        </div>
        <div
          v-if="canEdit"
          @click="edit()"
          title="edit position"
          :class="editing ? 'btn border-none' : 'btn btn-gray-400'"
          class="editPosition">
          <span v-if="!editing"><i class="fas fa-pencil-alt"></i></span>
          <span v-else class="h-center h-full"><i class="fas fa-times"></i></span>
        </div>
      </div>
    </div>
    <div v-if="editing" class="searchListContainer">
      <div class="searchList">
        <button
          v-if="competitor != null"
          class="searchListItem"
          @click.prevent="remove()">
          <i class="fas fa-trash"></i> REMOVE COMPETITOR
        </button>
        <button v-if="!competitors.length" class="searchListItem">No Competitors</button>
        <button
          v-for="(c, index) in competitors"
          @click.prevent="check(c, competitor)"
          class="searchListItem" :tabindex="index+2">
          {{ c.name }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {BsAlert} from "../../mixins/swal_mixins";

export default {
  name: "ShootOutAssignmentSelector",
  props: ['tournamentIn', 'ladder', 'ladderCompetitors', 'number', 'assignmentIn', 'showUnassigned'],
  data() {
    return {
      search: '',
      editing: false,
      assignment: this.assignmentIn,
    }
  },
  computed: {
    name() {
      if (this.assignment == null) return '';
      return this.assignment.name;
    },
    canEdit() {
      return true;
    },
    competitors() {
      let list = this.ladderCompetitors;
      if (this.showUnassigned) {
        let ids = this.assigned.map(m => m.competitor_id);
        list = list.filter((f) => {
          return !ids.includes(f.id);
        })
      }
      if (this.search.length) {
        list = list.filter((f) => {
          return f.name.toLowerCase().includes(this.search.toLowerCase());
        })
      }
      return list;
    },
    competitor() {
      if (this.assignment == null) return null;
      return this.tournamentIn.competitorList[this.assignment.competitor_id];
    },
    assignments() {
      return this.tournamentIn.ladder_assignments.filter(a => {
        return a.bale === 1 || (a.bale === 2 && a.step === 0);
      });
    },
    assigned() {
      let list = this.ladderCompetitors;
      let assignedList = [];
      for (let i = 0; i < list.length; i++) {
        let competitor = list[i];
        let exists = this.assignments.find(a => {
          return a.competitor_id === competitor.id;
        })
        if (exists) assignedList.push(exists);
      }
      return assignedList;
    },
  },
  methods: {
    edit() {
      if (this.editing === true) {
        this.editing = null;
        this.search = '';
      } else {
        this.editing = true;
        this.$nextTick(() => {
          this.$refs[`input_${this.number}`].focus();
        })
      }
    },
    
    remove() {
      let vm = this;
      this.$axios.post(`/tournaments/${this.tournamentIn.slug}/assign_ladder_remove`,
        {assignment_id: this.assignment.id},
      ).then((e) => {
        vm.$emit('forceUpdate');
      });
      this.assignment = null;
      this.editing = false;
    },
    clear() {
      if (this.search.length) {
        this.search = '';
      } else {
        this.editing = false;
      }
    },
    
    swap(first, second) {
      this.$axios.post(`/tournaments/${this.tournamentIn.slug}/assign_ladder_swap`,
        {first_id: first.id, second_id: second.id},
      ).then(_ => this.$emit('forceUpdate'))
    },
    
    check(newCompetitor, oldCompetitor) {
      let newExists = this.assigned.find(a => a.competitor_id === newCompetitor.id);
      let vm = this;
      if (newExists) {
        if (oldCompetitor) {
          let oldExists = this.assigned.find(a => a.competitor_id === oldCompetitor.id);
          BsAlert.fire({
            titleText: `${newCompetitor.name} is already assigned`,
            html: `Would you like to swap the assignment position with ${oldCompetitor.name}?`,
            icon: 'warning',
            showCloseButton: true,
            cancelButtonText: `No, remove ${oldCompetitor.name}`,
            confirmButtonText: 'Yes, swap positions',
            showLoaderOnConfirm: true,
          }).then(value => {
            if (value.value) {
              vm.swap(newExists, oldExists);
            } else if (value.dismiss === 'cancel') {
              this.$axios.post(`/tournaments/${vm.tournamentIn.slug}/assign_ladder_remove`,
                {assignment_id: newExists.id},
              ).then(_ => vm.update(newCompetitor))
            }
          })
        } else {
          BsAlert.fire({
            titleText: `${newCompetitor.name} is already assigned`,
            html: `Would you like to change the assignment to position ${this.number}?`,
            icon: 'warning',
            confirmButtonText: 'Yes',
            showLoaderOnConfirm: true,
          }).then(value => {
            if (value.value) {
              this.$axios.post(`/tournaments/${vm.tournamentIn.slug}/assign_ladder_remove`,
                {assignment_id: newExists.id},
              ).then(_ => vm.update(newCompetitor))
            }
          })
        }
      } else {
        this.update(newCompetitor);
      }
      //if not, update
      //else
      
    },
    
    update(competitor) {
      let count = this.number;
      let worst = this.ladder.start_ladder - 2;
      let step = worst - count + 1;
      let position = 'A'
      let bale = 1;
      
      if (count > worst) {
        step = 0;
        if (count === worst + 2) bale = 2;
      }
      let info = {
        'bale': bale,
        'bale_position': position,
        'tournament_id': this.ladder.tournament_id,
        'ladder_id': this.ladder.id,
        'competitor_id': competitor.id,
        'step': step,
      }
      this.$axios.post(`/tournaments/${this.tournamentIn.slug}/assign_ladder_single`, {data: info})
          .then(e => this.$emit('forceUpdate'));
      info['competitor'] = competitor;
      info['name'] = competitor.name;
      this.assignment = info;
      
      this.search = '';
      this.editing = false;
    },
  }
}
</script>


<style scoped>

.border-none {
  border: none;
}

.boxText {
  @apply text-center flex-grow overflow-x-hidden mx-2;
}

.nameBox {
  @apply overflow-x-hidden;
  text-overflow: ellipsis;
}

.editPosition {
  @apply cursor-pointer rounded-l-none;
}

.nameHolder {
  @apply flex justify-between p-0;
  overflow: hidden;
  white-space: nowrap;
}

.searchBox {
  @apply flex-grow pl-2;
}

.searchBox:focus {
  outline: none;
}

.searchListContainer {
  @apply bg-blue-800 text-blue-200 rounded-b w-full z-10;
}

.searchList {
  @apply mx-2 mb-1  overflow-y-auto max-h-xxs flex flex-col;
}

.searchListItem {
  @apply text-left;
}

.searchListItem:hover,
.searchListItem:focus {
  @apply bg-blue-600 cursor-pointer;
  outline: none;
}

.complete {
  @apply bg-blue-700 text-blue-200 rounded;
}

.incomplete {
  @apply bg-blue-200 text-blue-600 rounded;
}
</style>