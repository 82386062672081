import {useDisplayStore} from "../stores/DisplayStore";
import {BsAlert} from "./swal_mixins";

export default {
  data() {
    return {
      store: useDisplayStore(),
    }
  },
  computed: {
    isLadder() {
      return this.ladder != null;
    },
    ladder() {
      return this.store.ladder;
    },
    step() {
      return this.store.step;
    },
    roundWord() {
      return this.ladder == null ? 'Round' : 'Ladder';
    },
    roundIndex() {
      if (this.isLadder) return `${this.ladder.id}_${this.step}`;
      return this.round.id;
    },
    roundItem() {
      return this.ladder == null ? this.round : this.ladder;
    },
    is3d() {
      return this.tournament.target_style === 'Stakes'
    },
    assignedCompetitors() {
      return this.tournament.assignedCompetitors;
    },
    completion() {
      let vm = this;
      let complete = {rounds: {}};
      Object.values(this.assignedCompetitors.rounds).forEach(function (round) {
        vm.roundCompletion(complete, round);
      });
      return complete;
    },
    ladderCompletion() {
      let complete = {ladders: {}};
      let vm = this;
      let localExtras = Object.keys(this.store.localExtra);
      for (const ladder of Object.values(this.assignedCompetitors.ladder)) {
        let ladderObject = this.tournament.ladders.find((f) => f.id === ladder.ladder_id);
        let maxStep = ladderObject.ends_count * ladderObject.arrows_count;
        let ladderMax = 0;
        let ladderTotal = 0;
        let stepsComplete = {};
        for (const step of ladder.step) {
          let ladderId = `${ladder.ladder_id}_${step.step}`;
          let stepMax = 0;
          let stepTotal = 0;
          let extras = this.tournament.extra_arrows.filter((f) => {
            if (f.round_id !== null) return false;
            return f.ladder_id === ladder.ladder_id && f.step === step.step;
          });
          let extraCount = extras.length;
          if (localExtras.length) {
            for (let i = 0; i < ladderObject.ends_count; i++) {
              let key = `${vm.tournament.id}_${ladderId}_${i + 1}`;
              if (localExtras.includes(key)) {
                extraCount += vm.store.localExtra[key];
              }
            }
          }
          for (const bale of Object.values(step.bales)) {
            for (const competitorId of bale.competitors) {
              stepMax += maxStep;
              stepMax += extraCount;
              let score = this.scoreList[competitorId].ladders[ladderId];
              stepTotal += score != null ? score.complete : 0;
            }
          }
          ladderMax += stepMax;
          ladderTotal += stepTotal;
          stepsComplete[step.step] = stepMax === 0 ? 100 : Math.floor((stepTotal / stepMax) * 100);
        }
        complete.ladders[ladder.ladder_id] = {
          'steps': stepsComplete,
          'percent': ladderMax === 0 ? 100 : Math.floor((ladderTotal / ladderMax) * 100)
        };
      }
      return complete;
    },
    scoreList() {
      return this.tournament.scoreList;
    },
    notAccepted() {
      return this.store.notAccepted;
    },
    hasFailures() {
      return this.tournament.reshoots.length > 0;
    },
    offlineAssignment() {
      return this.$store.getters['tournament/getOfflineAssignment'];
    },
    offlineScores() {
      return this.$store.getters['tournament/getOfflineScores'];
    },
    offlineCompetitors() {
      return this.$store.getters['tournament/getOfflineCompetitors'];
    },
    round() {
      return this.store.round;
    },
    tournament() {
      return this.$store.state.tournament.tournament;
    },
    selectedCompetitors() {
      let round = this.roundItem.id;
      if (this.ladder != null) {
        if (this.assignedCompetitors.ladder[this.ladder.id] === undefined) return [];
        return this.assignedCompetitors.ladder[this.ladder.id].step[this.step].bales;
      }
      let time = this.store.lineTime.id;
      let location = this.store.location.id;
      if (this.assignedCompetitors.rounds[round] === undefined) return [];
      return this.assignedCompetitors.rounds[round].line_times[time].locations[location].bales;
    },
    filteredCompetitors() {
      let competitors = {};
      let restrictedBales = this.store.restrictedBales.length ? this.store.restrictedBales : null;
      let shownBales = this.store.shownBales.length ? this.store.shownBales : null;
      let vm = this;
      Object.keys(this.selectedCompetitors).forEach(function (bale) {
        if (!restrictedBales || restrictedBales.indexOf(bale) !== -1) {//no bale restriction
          if (!shownBales || shownBales.indexOf(bale) !== -1) {//no shown restriction
            competitors[bale] = {};
            Object.values(vm.selectedCompetitors[bale].positions).forEach(function (position) {
              if (position.competitor && vm.competitorIsNotRestricted(position.competitor)) {
                competitors[bale][position.position] = position.competitor
              }
            })
          }
        }
      });
      return competitors;
    },
    validBaleCompetitors() {
      let competitors = {};
      let vm = this;
      Object.keys(this.filteredCompetitors).forEach((bale) => {
        let value = vm.filteredCompetitors[bale];
        if (Object.keys(value).length) {
          competitors[bale] = value;
        }
      })
      return competitors;
    },
    endCompletion() {
      let list = {};
      let vm = this;
      for (let end = 1; end < this.roundItem.ends_count + 1; end++) {
        let scoresCount = 0;
        let competitorCount = 0;
        let roundWord = `${this.roundWord.toLowerCase()}s`;
        let index = vm.roundItem.id;
        if (this.isLadder) index += `_${vm.step}`;
        Object.values(this.filteredCompetitors).forEach(bale => {
          Object.values(bale).forEach(competitor => {
            competitorCount++;
            scoresCount += vm.scoreList[competitor][roundWord][index].ends[end].complete;
          })
        });
        let scoreMax = competitorCount * this.roundItem.arrows_count;
        if (this.isLadder) {
          let extraArrows = this.extraArrowCount(this.ladder.id, this.step, end);
          let key = `${this.tournament.id}_${index}_${end}`;
          if (key in this.store.localExtra) {
            extraArrows += this.store.localExtra[key];
          }
          scoreMax += competitorCount * extraArrows;
        }

        list[end] = scoreMax === 0 ? 100 : Math.floor((scoresCount / scoreMax) * 100);
      }
      return list;
    },
  }
  ,
  methods: {
    extraArrowCount(ladderId, step, end) {
      if (this.isLadder && this.tournament.extra_arrows.length) {
        let copy = [...this.tournament.extra_arrows];
        let filtered = copy.filter(a => {
          return a.ladder_id === ladderId && a.step === step && a.end === end;
        })
        return filtered.length;
      }
      return 0;
    }
    ,
    ladderCounts(ladder) {
      let selectedLadder = ladder ?? this.ladder;
      let list = [];
      if (this.ladder.shoot_out) {
        for (let i = 0; i < this.ladder.start_ladder - 1; i++) {
          list.push(i);
        }
        return list;
      }
      let start = selectedLadder.start_ladder;
      let end = selectedLadder.end_ladder;
      while (start >= end) {
        list.push(start);
        start = start / 2;
      }
      return list;
    }
    ,
    competitorIsNotRestricted(competitor) {
      if (!this.store.restrictedCompetitors.length) return true;
      let list = this.store.restrictedCompetitors.map(m => m.id);
      return this.store.restrictedCompetitors.includes(competitor);
    },
    getColorFromPercentage(percentage) {
      if (percentage > 0) return percentage >= 100 ? 'text-blue-600' : 'text-blue-400';
      return 'text-gray-600';
    }
    ,
    getCompletion(round, location, time) {
      if (location && time) return this.completion.rounds[round].locTime[location + '_' + time];
      return this.completion.rounds[round].percent;
    }
    ,
    getLadderCompletion(ladder, step) {
      if (step != null) return this.ladderCompletion.ladders[ladder].steps[step];
      return this.ladderCompletion.ladders[ladder].percent;
    },
    async setLadderWinner(step, ladder) {
      return await this.$axios.post(`/tournaments/${this.tournament.slug}/assign_winner`, {
        'ladder': ladder,
        'step': step,
      }).then((response) => {
        return response;
      });
    },
    localTable() {
      let list = [['name', 'total', 'end']];
      for (let bale of Object.keys(this.filteredCompetitors)) {
        for (let pos of Object.keys(this.filteredCompetitors[bale])) {
          let cid = this.filteredCompetitors[bale][pos];
          let cName = this.tournament.competitorList[cid].name;
          let cScore = this.scoreList[cid][`${this.roundWord.toLowerCase()}s`][this.roundIndex];
          let roundScore = cScore.points;
          if (cScore.X > 0) roundScore += `-${cScore.X}x`;
          let eScore = cScore['ends'][this.store.end];
          let endScore = eScore.points;
          if (eScore.X > 0) endScore += `-${eScore.X}x`;
          list.push([cName, roundScore, endScore]);
        }
      }
      return list;

    },
    localTableHtml(table, message) {
      let html = `<div><div class="mb-4 font-bold">${message}</div>`;
      for (let row of table) {
        html += '<div class="flex">';
        for (let cell of row) {
          html += `<div class="flex-1 text-center">${cell}</div>`;
        }
        html += '</div>';
      }
      html += '</div>';
      return html;
    },
    tieFromTable(table) {
      if (table.length === 3) {
        let first = table[1][1];
        let second = table[2][1];
        if (first === second) return true;
      }
      return false;
    },
    addArrowPrompt(table) {
      return BsAlert.fire({
        titleText: `There was a tie!`,
        html: this.localTableHtml(table, `Add an arrow and break the tie before assigning the winner.`),
        cancelButtonText: 'close',
        showConfirmButton: false,
      })
    },
    checkEndCompletion() {
      let endWord = this.is3d ? 'Stake' : 'End';
      let vm = this;
      if ((!this.notAccepted.length) && this.endCompletion[this.store.end] >= 100) {
        let next = this.store.end + 1;
        let table = this.localTable();
        if (this.isLadder && this.ladder.shoot_out && this.step < this.ladder.start_ladder - 1) {
          let steps = this.ladderCompletion.ladders[this.ladder.id].steps;
          let completion = steps[this.step];
          let next = this.step + 1;
          let nextCompletion = steps[next];
          if (completion === 100 && nextCompletion === 0) {
            if (this.assignedCompetitors.ladder[this.ladder.id].step[next].competitors_count !== 2) {
              let tie = this.tieFromTable(table);
              if (tie) return this.addArrowPrompt(table);
              return BsAlert.fire({
                titleText: `${this.ladder.name} Step ${next} scoring completed!`,
                html: this.localTableHtml(table, `Assign winner and go to next step?`),
                confirmButtonText: `Yes`,
                cancelButtonText: `No`,
                showLoaderOnConfirm: true,
                async preConfirm(inputValue) {
                  if (inputValue) {
                    await vm.setLadderWinner(vm.step, vm.ladder.id).then((value) => {
                      if (value && value.data && value.data.success) {
                        vm.$emit('forceUpdate');
                        vm.store.step = next;
                        vm.store.end = 1;
                        vm.store.localExtra = {};
                      }
                    });
                  }
                }
              })
            }
          }
        }
        if (this.endCompletion.hasOwnProperty(next) && this.endCompletion[next] < 100) {
          let message = `Are you ready to score ${endWord} ${next}?`;
          BsAlert.fire({
            titleText: `${endWord} ${this.store.end} scoring completed!`,
            html: this.isLadder ? this.localTableHtml(table, message) : message,
            confirmButtonText: `Continue to ${endWord} ${next}`,
            cancelButtonText: `Stay on ${endWord} ${this.store.end}`,
          }).then(({dismiss}) => {
            if (!dismiss) {
              this.store.end = next;
            }
          })
        } else if (this.is3d && this.endCompletion[1] < 100) {
          BsAlert.fire({
            titleText: `${endWord} ${this.store.end} scoring completed!`,
            html: `Are you ready to score ${endWord} 1?`,
            confirmButtonText: `Continue to ${endWord} 1`,
            cancelButtonText: `Stay on ${endWord} ${this.store.end}`,
          }).then(({dismiss}) => {
            if (!dismiss) {
              this.store.end = 1;
            }
          })
        } else {
          void BsAlert.fire({
            title: `${endWord} ${this.store.end} scoring completed!`, showCancelButton: false,
          })
        }
      }
    },
    locationAndTimeCompletion(complete, roundData, lineTime, location, tRound, round) {
      let vm = this;
      let ltScores = 0;
      let ltCompetitors = 0;
      let restricted = vm.store.restrictedBales.length ? vm.store.restrictedBales : null;
      Object.values(location.bales).forEach(function (bale) {
        if (!restricted || restricted.indexOf(parseInt(bale)) !== -1) {
          Object.values(bale.competitors).forEach(function (competitor) {
            roundData.competitorCount++;
            ltCompetitors++;
            let list = vm.tournament.scoreList[competitor];
            let competitorScores = list.rounds[round.round_id].complete;
            roundData.scoresCount += competitorScores;
            ltScores += competitorScores;
          })
        }
      });
      let ltMax = ltCompetitors * tRound.arrows_count * tRound.ends_count;
      let locTime = `${location.location_id}_${lineTime.line_time_id}`;
      let completion = ltMax === 0 ? 100.0 : Math.floor((ltScores / ltMax) * 100.0);
      complete.rounds[round.round_id].locTime[locTime] = completion;
    }
    ,
    roundCompletion(complete, round) {
      let vm = this;
      let roundData = {scoresCount: 0, competitorCount: 0};
      complete.rounds[round.round_id] = {percent: 0, locTime: {}};
      let tRound = vm.tournament.rounds.find(f => f.id === round.round_id);
      Object.values(round.line_times).forEach(function (lineTime) {
        Object.values(lineTime.locations).forEach(function (location) {
          vm.locationAndTimeCompletion(complete, roundData, lineTime, location, tRound, round);
        })
      });
      let roundScoreMax = roundData.competitorCount * tRound.arrows_count * tRound.ends_count;
      let completion = roundScoreMax === 0 ? 100 : Math.floor((roundData.scoresCount / roundScoreMax) * 100);
      complete.rounds[round.round_id].percent = completion;
    }
    ,
    updateLocTime(location, time) {
      if (this.store.notAccepted.length) {
        void BsAlert.fire({
          title: 'Scores must be accepted or denied before switching time or location',
          showCancelButton: false,
        });
      } else {
        this.store.$patch({
          lineTime: time,
          location: location,
        })
      }
    }
    ,
    updateLadder(ladder) {
      if (this.store.notAccepted.length) {
        void BsAlert.fire({
          title: 'Scores must be accepted or denied before switching round',
          showCancelButton: false,
        });
      } else {
        this.store.ladder = ladder;
        if (this.incCompetitor) {
          //handle self scoring
        }
        if (this.store.step >= this.ladderCounts(ladder).length) {
          this.store.step = 0;
        }
      }
    }
    ,
    updateStep(step) {
      if (this.store.notAccepted.length) {
        void BsAlert.fire({
          title: 'Scores must be accepted or denied before switching time or location',
          showCancelButton: true,
        });
      } else {
        this.store.step = step;
      }
    },
    updateRound(round) {
      if (this.store.notAccepted.length) {
        void BsAlert.fire({
          title: 'Scores must be accepted or denied before switching round',
          showCancelButton: true,
        });
      } else {
        this.store.round = round;
        let location = round.locations[0];
        let lineTime = round.line_times[0];
        if (this.incCompetitor) {
          let assigned = this.incCompetitor.assignments.find(f => f.round_id === round.id);
          location = round.locations.find(f => f.name === assigned.location);
          lineTime = round.line_times.find(f => f.id === parseInt(assigned.line_time));
        }
        this.updateLocTime(location, lineTime);
      }
    }
    ,
    async unlockDevice() {
      this.store.$patch({
        restrictedBales: [],
        restrictedCompetitors: [],
        restrictedLocations: [],
        restrictedTimes: [],
        restrictedRounds: [],
        showLock: false,
        locked: false,
      })
      let usedCode = await this.$localForage.getItem(this.tournament.id + '_code');
      if (usedCode != null) {
        let device = this.$store.state.device;
        let vm = this;
        await this.$axios.post('/device/remove_tournament', {
          'device': device.device.device_id,
          'tournament': this.tournament.id,
        }).then(function (data) {
          let d = data.data.device;
          vm.$store.commit('device/SET', d);
        })
      }
      void this.$localForage.removeItem(this.tournament.id + '_lock');
      void this.$localForage.removeItem(this.tournament.id + '_locked_bales');
      void this.$localForage.removeItem(this.tournament.id + '_locked_competitors');
      void this.$localForage.removeItem(this.tournament.id + '_locked_locations');
      void this.$localForage.removeItem(this.tournament.id + '_locked_times');
      void this.$localForage.removeItem(this.tournament.id + '_locked_rounds');
      void this.$localForage.removeItem(this.tournament.id + '_locked_soft');
      void this.$localForage.removeItem(this.tournament.id + '_code');
      void this.$store.dispatch('tournament/setUnlocked', this.tournament.slug);
    }
    ,
  }
}
;
